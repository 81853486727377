<template>
  <div class="card">
    <div class="card-body">
      <div v-if="isLoading" class="d-flex justify-content-center">
        <b-spinner></b-spinner>
      </div>
      <div v-else>
        <div v-for="group in Object.keys(groupedDescription)" :key="group">
          <h3>{{$t(`services.${group}`)}}</h3>
          <div class="d-flex" v-for="dataItem in groupedDescription[group]" :key="dataItem.id">
            <div class="flex-shrink-0">
              <img :src="dataItem.icon_url" class="service-icon">
            </div>
            <div class="flex-grow-1 ms-3">
              <h5>{{ dataItem.name }}</h5>

              <p>{{dataItem.description}}</p>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import 'core-js/actual'
function groupObj({search_params}) {
  // search_params.push("InternetArchive")
  if(search_params.includes('isdn')) {
    return '1isdn';
  } else if(search_params.includes('image')) {
    return '2photo'
  } else if(search_params.includes('email')) {
    return '3GoogleAccountSearch'
  } else if(search_params.includes('username')) {
    return '4NicknameSearch'
  } else if(search_params.includes('car_plate_num')) {
    return '5Auto'
  } else if (search_params.includes('social_url')){
    return '6InternetArchive'
  } else if (search_params.includes('point')){
    return '7GeoSearch'
  }
}

export default {
  name: "Description",
  data() {
    return {
      isLoading: false,
      groupedDescription: {}
    };
  },
  computed: {
    ...mapState({
      description: state => state.ui.description
    }),
    locale() {
      if(this.$i18n.locale === 'ua') {
        return 'uk';
      }
      return this.$i18n.locale
    }
  },
  methods: {
    beautyNumbers(val) {
      let temp;
      if (typeof val !== 'undefined') {
        temp = val.toString()
        if(temp.length >= 4 && temp.length < 7) {
          return `${(val/1000).toFixed(2)}${this.$t('base_source_date.thousand')}`
        } else if (temp.length >= 7 && temp.length < 10) {
          let count = temp.length - 7;
          temp = temp.substr(0, temp.length - 3)
          let first = temp.substr(0, temp.length - 3)
          let lust = temp.substr(1 + count)
          return first + ',' + lust + this.$t('base_source_date.million');
        } else if (temp.length >= 10) {
          let count = temp.length - 10;
          temp = temp.substr(0, temp.length - 6)
          let first = temp.substr(0, temp.length - 3)
          let lust = temp.substr(1 + count)
          return first + ',' + lust + this.$t('base_source_date.billion');
        }
        return val.toLocaleString('ru')
      }
    },
    getData(locale) {
      this.isLoading = true;
      this.$store.dispatch('ui/getDescription', locale).then(() => {
        this.isLoading = false;
        this.groupedDescription = this.description.data.group(groupObj);
      });
    }
  },
  mounted() {
    this.getData(this.locale)
  },
  watch: {
    '$i18n.locale': function () {
      this.getData(this.locale)
    }
  }
}
</script>

<style scoped>
iframe {
  width: 100%;
  height: calc(100vh - 60px);
}
img.service-icon {
  width: 40px;
}
</style>